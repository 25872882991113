<template>
  <div class="home">
    <b-jumbotron fluid class="wallpaper text-white mb-0">
      <h1 class="header">Build value while enjoying life.</h1>
      <p class="lead mr-4">
        Life can be complicated, but having a pool doesn't have to be. Let us
        help you build the backyard of your dreams without breaking your wallet.
      </p>
    </b-jumbotron>

    <b-container class="pt-5 pb-5">
      <b-row>
        <b-col md="4">
          <b-card
            class="why-crd"
            border-variant="secondary"
            header="Free Estimates!"
            align="center"
          >
            <b-card-text class="text-white">
              <p>
                Contact us now for a Free Estimate on anything and everything
                swimming pool related.
              </p>
              <p>
                <b-button pill variant="info" href="/Contact"
                  >Get a Free Quote<span class="pl-2"
                    ><i class="fas fa-envelope"></i></span
                ></b-button>
              </p>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="8">
          <div class="pl-5">
            <h1 class="why-header">
              <span style="color:#40909B">Why</span> Choose Us?
            </h1>
            <ul class="fa-ul why-bullets">
              <li>
                <span class="fa-li" style="color:#307181"
                  ><i class="fas fa-check fa-lg"></i
                ></span>
                We've been doing this a really long time... and its our passion.
                With over 10 years and 100s of backyard transformations, we
                never get tired of what we do!
              </li>
              <li>
                <span class="fa-li" style="color:#307181"
                  ><i class="fas fa-check fa-lg"></i
                ></span>
                We're local, we're your neighbors! unlike the big franchises, we
                pride ourselves on being a small business that serves our
                community.
              </li>
              <li>
                <span class="fa-li" style="color:#307181"
                  ><i class="fas fa-check fa-lg"></i
                ></span>
                We're licensed, bonded, and insured... and certified to do it
                right the first time. We don't have time to do a bad job!
              </li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid>
      <b-row>
        <b-col xl="3" lg="6" class="quick-look-block px-0">
          <b-img
            src="../assets/images/construction-cropped.jpg"
            class="quick-look-image"
          ></b-img>
          <div class="quick-look-txtbox">
            <div class="txtbox-text text-center">
              <h4>YOUR DREAM, OUR JOB.</h4>
              <p>
                You have a crazy idea of getting a pool, but you don't know
                where to start. Let AB Pools help you through the process.
              </p>
              <b-button pill variant="info" href="/pools/new-projects"
                >Read More<span class="pl-2"
                  ><i class="fas fa-glasses"></i></span
              ></b-button>
            </div>
          </div>
          <div class="quick-look-txtbox-front">
            <div class="txtbox-text text-center">
              <h4>YOUR DREAM, OUR JOB.</h4>
              <p>
                You have a crazy idea of getting a pool, but you don't know
                where to start. Let AB Pools help you through the process.
              </p>
              <b-button pill variant="info" href="/pools/new-projects"
                >Read More<span class="pl-2"
                  ><i class="fas fa-glasses"></i></span
              ></b-button>
            </div>
          </div>
        </b-col>
        <b-col xl="3" lg="6" class="quick-look-block px-0">
          <b-img
            src="../assets/images/service-cropped.jpg"
            class="quick-look-image"
          ></b-img>
          <div class="quick-look-txtbox">
            <div class="txtbox-text text-center">
              <h4>RELAX. WE"LL GET THAT.</h4>
              <p>
                We know that maintaining a pool can interfere with your time
                off... and life. Let us help you service and maintain your pool
                throughout the year.
              </p>
              <b-button pill variant="info" href="/pools/service"
                >Read More<span class="pl-2"
                  ><i class="fas fa-glasses"></i></span
              ></b-button>
            </div>
          </div>
          <div class="quick-look-txtbox-front">
            <div class="txtbox-text text-center">
              <h4>RELAX. WE"LL GET THAT.</h4>
              <p>
                We know that maintaining a pool can interfere with your time
                off... and life. Let us help you service and maintain your pool
                throughout the year.
              </p>
              <b-button pill variant="info" href="/pools/service"
                >Read More<span class="pl-2"
                  ><i class="fas fa-glasses"></i></span
              ></b-button>
            </div>
          </div>
        </b-col>
        <b-col xl="3" lg="6" class="quick-look-block px-0">
          <b-img
            src="../assets/images/restoration-cropped.jpg"
            class="quick-look-image"
          ></b-img>
          <div class="quick-look-txtbox">
            <div class="txtbox-text text-center">
              <h4>GOOD AS NEW... OR BETTER?</h4>
              <p>
                So you've found yourself responsible for a pool that needs a bit
                of a makeover. We love a good challenge... and don't worry,
                there's nothing we haven't seen!
              </p>
              <b-button pill variant="info" href="/pools/service"
                >Read More<span class="pl-2"
                  ><i class="fas fa-glasses"></i></span
              ></b-button>
            </div>
          </div>
          <div class="quick-look-txtbox-front">
            <div class="txtbox-text text-center">
              <h4>GOOD AS NEW... OR BETTER?</h4>
              <p>
                So you've found yourself responsible for a pool that needs a bit
                of a makeover. We love a good challenge... and don't worry,
                there's nothing we haven't seen!
              </p>
              <b-button pill variant="info" href="/pools/service"
                >Read More<span class="pl-2"
                  ><i class="fas fa-glasses"></i></span
              ></b-button>
            </div>
          </div>
        </b-col>
        <b-col xl="3" lg="6" class="quick-look-block px-0">
          <b-img
            src="../assets/images/jacuzzi-cropped.jpg"
            class="quick-look-image"
          ></b-img>
          <div class="quick-look-txtbox">
            <div class="txtbox-text text-center">
              <h4 class="text-nowrap">IT'S A SPA-CATION!</h4>
              <p>
                Transform your backyard or deck into a mini-getaway with a
                jacuzzi and some bubbly! We can help you pick the right tub so
                all you have to do is relax.
              </p>
              <b-button pill variant="info" href="/spas/installation"
                >Read More<span class="pl-2"
                  ><i class="fas fa-glasses"></i></span
              ></b-button>
            </div>
          </div>
          <div class="quick-look-txtbox-front">
            <div class="txtbox-text text-center">
              <h4 class="text-nowrap">IT'S A SPA-CATION!</h4>
              <p>
                Transform your backyard or deck into a mini-getaway with a
                jacuzzi and some bubbly! We can help you pick the right tub so
                all you have to do is relax.
              </p>
              <b-button pill variant="info" href="/spas/installation"
                >Read More<span class="pl-2"
                  ><i class="fas fa-glasses"></i></span
              ></b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid class="py-5 px-5">
      <b-row>
        <b-col md="8" offset-md="2" class="service-areas text-center">
          <h1 class="county-header">
            <span style="color:#40909B">Counties</span> We've Served
            <span style="color:#40909B" class="ml-2"
              ><i class="fas fa-map-marked"></i
            ></span>
          </h1>
        </b-col>
      </b-row>
      <b-row class="">
        <b-col md="2" offset-md="1" class="service-column text-left">
          <b>Northern Virginia</b><br />
          Arlington County<br />
          Loudoun County<br />
          Prince William County<br />
          Alexandria<br />
          Fairfax County
        </b-col>
        <b-col md="2" class="service-column text-left">
          <b>Central Virginia</b><br />
          Dumfries<br />
          Quantico<br />
          Stafford County<br />
          Fredericksburg<br />
          Spotsylvania County
        </b-col>
        <b-col md="2" class="service-column text-left">
          <b>Richmond Area</b><br />
          Henrico County<br />
          Richmond City<br />
          Chesterfield County<br />
          Mechanicsville<br />
          Short Pump<br />
          Ashland
        </b-col>
        <b-col md="2" class="service-column text-left">
          <b>Western Corridor</b><br />
          Culpeper County<br />
          Orange County<br />
          Locust Grove<br />
          Lake Anna
        </b-col>
        <b-col md="2" class="service-column text-left">
          <b>Eastern Corridor</b><br />
          King George County<br />
          Caroline County<br />
          Bowling Green<br />
          Dahlgren
        </b-col>
      </b-row>
    </b-container>

    <b-container
      fluid
      class="my-5 py-4 reviewContainer border-top border-bottom"
    >
      <agile :dots="false" :autoplay-speed="5000">
        <div class="slide">
          <b-container>
            <b-row>
              <b-col class="text-left">
                <span class="text-white"
                  ><i class="fas fa-quote-left fa-2x"></i
                ></span>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <p class="review-content text-left text-white">
                  These guys know what they are doing. Had 18 foot above ground
                  pool assembled and ready in half a day. Showed up on time,
                  worked without a break, Bobcat work was amazing, left back
                  yard in better condition than they found it! Great price, too.
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-right">
                <span class="text-white"
                  ><i class="fas fa-quote-right fa-2x"></i
                ></span>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-center">
                <span class="star-full"><i class="fa fa-star"></i></span>
                <span class="star-full ml-2"><i class="fa fa-star"></i></span>
                <span class="star-full ml-2"><i class="fa fa-star"></i></span>
                <span class="star-full ml-2"><i class="fa fa-star"></i></span>
                <!-- <span class="star-full ml-2"><i class="fa fa-star"></i></span> -->
                <span class="star-full ml-2"
                  ><i class="fa fa-star-half"></i
                ></span>
                <span class="text-white ml-3 review-age">7 months ago</span>
                <hr class="my-4" />
                <p class="text-white">- Ivan H.</p>
              </b-col>
            </b-row>
          </b-container>
        </div>

        <div class="slide">
          <b-container>
            <b-row>
              <b-col class="text-left">
                <span class="text-white"
                  ><i class="fas fa-quote-left fa-2x"></i
                ></span>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <p class="review-content text-left text-white">
                  We would give 6 stars if we could. Dennis and his team are
                  prompt, polite, respectful and most importantly HIGHLY
                  SKILLED!
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-right">
                <span class="text-white"
                  ><i class="fas fa-quote-right fa-2x"></i
                ></span>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-center">
                <span class="star-full"><i class="fa fa-star"></i></span>
                <span class="star-full ml-2"><i class="fa fa-star"></i></span>
                <span class="star-full ml-2"><i class="fa fa-star"></i></span>
                <span class="star-full ml-2"><i class="fa fa-star"></i></span>
                <span class="star-full ml-2"><i class="fa fa-star"></i></span>
                <span class="star-full ml-2"><i class="fa fa-star"></i></span>
                <!-- <span class="star-full ml-2"><i class="fa fa-star-half"></i></span> -->
                <span class="text-white ml-3 review-age">6 months ago</span>
                <hr class="my-4" />
                <p class="text-white">- Kerrie S.</p>
              </b-col>
            </b-row>
          </b-container>
        </div>

        <div class="slide">
          <b-container>
            <b-row>
              <b-col class="text-left">
                <span class="text-white"
                  ><i class="fas fa-quote-left fa-2x"></i
                ></span>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <p class="review-content text-left text-white">
                  Denis has been nothing but professional and helpful throughout
                  the entire process, to include follow on opening and closing
                  services. He helped us navigate through the various options of
                  designs to create an outstanding pool for our family. Great
                  job
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-right">
                <span class="text-white"
                  ><i class="fas fa-quote-right fa-2x"></i
                ></span>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-center">
                <span class="star-full"><i class="fa fa-star"></i></span>
                <span class="star-full ml-2"><i class="fa fa-star"></i></span>
                <span class="star-full ml-2"><i class="fa fa-star"></i></span>
                <span class="star-full ml-2"><i class="fa fa-star"></i></span>
                <span class="star-full ml-2"><i class="fa fa-star"></i></span>
                <span class="star-full ml-2"><i class="fa fa-star"></i></span>
                <!-- <span class="star-full ml-2"><i class="fa fa-star-half"></i></span> -->
                <span class="text-white ml-3 review-age">6 months ago</span>
                <hr class="my-4" />
                <p class="text-white">- Steve S.</p>
              </b-col>
            </b-row>
          </b-container>
        </div>

        <div class="slide">
          <b-container>
            <b-row>
              <b-col class="text-left">
                <span class="text-white"
                  ><i class="fas fa-quote-left fa-2x"></i
                ></span>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <p class="review-content text-left text-white">
                  Dennis and his crew have been to our home several times to
                  install our above ground pool. He was recommended by our pool
                  sales place and we are very happy with his service and
                  professionalism. We highly recommend Dennis for your above
                  ground pool install!
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-right">
                <span class="text-white"
                  ><i class="fas fa-quote-right fa-2x"></i
                ></span>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-center">
                <span class="star-full"><i class="fa fa-star"></i></span>
                <span class="star-full ml-2"><i class="fa fa-star"></i></span>
                <span class="star-full ml-2"><i class="fa fa-star"></i></span>
                <span class="star-full ml-2"><i class="fa fa-star"></i></span>
                <span class="star-full ml-2"><i class="fa fa-star"></i></span>
                <!-- <span class="star-full ml-2"><i class="fa fa-star"></i></span> -->
                <span class="star-full ml-2"
                  ><i class="fa fa-star-half"></i
                ></span>
                <span class="text-white ml-3 review-age">6 months ago</span>
                <hr class="my-4" />
                <p class="text-white">- Sanda T.</p>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <template slot="prevButton"
          ><i class="fas fa-chevron-left"></i
        ></template>
        <template slot="nextButton"
          ><i class="fas fa-chevron-right"></i
        ></template>
      </agile>
    </b-container>

    <b-container>
      <b-row>
        <b-col md="8" offset-md="2" class="service-areas text-center">
          <h1 class="county-header">
            <span style="color:#40909B">What</span> We Do Best
            <span style="color:#40909B" class="ml-2"
              ><i class="fas fa-swimming-pool"></i
            ></span>
          </h1>
        </b-col>
      </b-row>
      <b-row class="pt-2">
        <b-col md="4" class="service-column text-left">
          <b>Custom Pools</b><br />
          Vinyl Liner Pools<br />
          Concrete Pools<br />
          Fiberglass Pools<br />
          Backyard Inground Pools<br />
          Above-Ground Pools
        </b-col>
        <b-col md="4" class="service-column text-left">
          <b>Features</b><br />
          Salt Water Systems<br />
          Water Features<br />
          Custom Lighting<br />
          Pool Covers<br />
          Landscaping | Lighting | Fencing<br />
          Pool Heaters
        </b-col>
        <b-col md="4" class="service-column text-left">
          <b>Services</b><br />
          Pool Restoration/Renovations<br />
          Vinyl Repair<br />
          Liner Replacements<br />
          Open/Close Pool
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { VueAgile } from "vue-agile";

export default {
  name: "Home",

  components: {
    agile: VueAgile
  },

  data() {
    return {
      slide: 0
    };
  },

  methods: {}
};
</script>

<style scoped lang="scss">
.jumbotron .container {
  margin-top: -53px;
  @media #{$mq-phone} {
    margin-top: 0px;
  }
}

.wallpaper {
  height: 0;
  padding-bottom: 40%;
  background-image: url("../assets/images/home/home-wallpaper.jpg");
  background-position: center center;
  background-size: cover;

  @media #{$mq-phone} {
    background-image: url("../assets/images/home/home-wallpaper-phone.jpg");
    padding-bottom: 180%;
    margin-right: 0;
  }
}

.header {
  font-size: $base-font-size * 3;
  font-family: "Permanent Marker", cursive;
}

.lead {
  font-size: $base-font-size * 1.1;
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

.why-crd .card-header {
  font-size: $base-font-size * 1.2;
  font-family: "Lato", sans-serif;
  background-color: #ececec;
}

.why-crd .card-body {
  font-size: $base-font-size * 0.8;
  font-family: "Lato", sans-serif;
  background-image: url("../assets/images/card-pool.jpg");
  background-position: center center;
  background-size: cover;
}

.why-header {
  font-size: $base-font-size * 2.5;
  font-family: "Roboto", sans-serif;

  @media #{$mq-phone} {
    padding-top: 0.5em;
  }
}

.why-bullets {
  font-size: $base-font-size * 0.89;
  font-family: "Lato", sans-serif;

  li {
    padding-bottom: 1em;
  }
}

.quick-look-image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.quick-look-txtbox {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #1f5162;
}

.quick-look-block:hover .quick-look-image {
  opacity: 0.3;
}

.quick-look-block:hover .quick-look-txtbox {
  opacity: 0.6;
}

.txtbox-text {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: $base-font-size * 0.89;
  font-family: "Roboto", sans-serif;
}

.county-header {
  font-size: $base-font-size * 1.2;
  font-family: "Roboto", sans-serif;
}

.service-areas {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: $base-font-size * 2.75;
}

.service-column {
  font-family: "Roboto", sans-serif;
  @media #{$mq-phone} {
    text-align: center !important;
  }
}

.review-age {
  font-size: $base-font-size * 0.85;
  font-family: "Roboto", sans-serif;
}

.reviewContainer {
  background-color: #307183;
}

.review-content {
  font-size: $base-font-size * 1.3;
  font-family: "Permanent Marker", cursive;
}

.star-full {
  color: orange;
}

.star-empty {
  color: gray;
}

// .choose {
//   background-color: #4fb2cb;
// }

// .choose-text {
//   font-size: $base-font-size*1.3;
//   font-family: 'Roboto', sans-serif;
// }

// .step-title {
//   font-family: 'Rock Salt', cursive;
//   font-size: $base-font-size*1.2;
//   color: #404040;
// }

// .step-body {
//   font-size: $base-font-size*.9;
//   font-family: 'Roboto', sans-serif;
//   color: #585858;
// }

// .step-body a {
//   font-size: $base-font-size;
//   font-family: 'Roboto', sans-serif;
//   color: #585858;
//   font-weight: bold;
//   text-decoration: none;
//   &:hover {
//     text-decoration: underline;
//   }
// }
</style>

<style lang="scss">
.agile {
  position: relative;
}

.agile__nav-button {
  background: transparent;
  border: none;
  color: #ccc;
  cursor: pointer;
  font-size: 24px;
  transition-duration: 0.3s;
  &:hover {
    color: #888;
  }
}

.agile__actions {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
